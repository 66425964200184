<template>
  <div v-html="summary"></div>
  <template v-for="motion in intervention.introduced" :key="motion">
    <div :id="'m-'+point+'-'+curMotion+motion.order.toString()" class="motion">
      <div class="text">
        <div class="preamble" v-html="markdownify(motion.preamble)"></div>
        <div v-if="motion.old_text !== '' && motion.proposition === 'l.M'" class="operative diff" v-html="markdownify(updateMotion(motion))"></div>
        <div v-else class="operative" v-html="markdownify(motion.operative)"></div>
      </div>
      <div class="sponsors" v-if="motion.sponsors.length > 0">{{ $tc('motion.sponsorlabel', motion.sponsors.length) }}
        <ul>
          <li v-for="sponsor in motion.sponsors" :key="sponsor" class="intervener">{{ participants[sponsor] }}</li>
        </ul>
      </div>
    </div>
    <div class="discussion" v-if="motion.interventions.length > 0">
      <Intervention v-for="intervention in motion.interventions" :key="intervention.id" :intervention="intervention" :participants="participants" :curMotion="curMotion+motion.order+'.'" :point="point" :meetingCode="meetingCode" />
    </div>
    <div class="motion-reference">
      <template v-for="vote in motion.votes" :key="vote">
        <div v-if="vote.requester !== null" class="vote-request">
          {{ $t('motion.voterequest') }}
          <ul><li class="intervener">{{ participants[vote.requester] }}</li></ul>
        </div>
        <div v-if="vote.passed && vote.favour === 0 && vote.requester === null">{{ $t('motion.acclaimed') }}</div>
        <div v-if="vote.passed && vote.favour > 0">{{ $t('motion.passes', { favour: vote.favour, oppose: vote.oppose, abstain: vote.abstain }) }}</div>
        <div v-if="!vote.passed && vote.oppose === 0 && vote.requester === null">{{ $t('motion.dropped') }}</div>
        <div v-if="!vote.passed && vote.oppose > 0">{{ $t('motion.fails', { favour: vote.favour, oppose: vote.oppose, abstain: vote.abstain }) }}</div>
      </template>
      <div class="motion-code"><a :href="'#m-'+point+'-'+curMotion+motion.order.toString()">{{ meetingCode }}-{{ point+'-'+curMotion+motion.order.toString() }}</a></div>
    </div>
  </template>
</template>
<script>
import VMdEditor, { xss } from '@kangc/v-md-editor'
import DiffMatchPatch from 'diff-match-patch'

const dmp = new DiffMatchPatch()

DiffMatchPatch.prototype.toHtml = function (diffs) {
  var html = []
  for (var x = 0; x < diffs.length; x++) {
    var op = diffs[x][0]
    var data = diffs[x][1]
    var text = data.replace(/&/g, '&amp;').replace(/</g, '&lt;')
      .replace(/>/g, '&gt;').replace(/\n/g, '&para;<br>')
    switch (op) {
      case DiffMatchPatch.DIFF_INSERT:
        html[x] = '<ins>' + text + '</ins>'
        break
      case DiffMatchPatch.DIFF_DELETE:
        html[x] = '<del>' + text + '</del>'
        break
      case DiffMatchPatch.DIFF_EQUAL:
        html[x] = text
        break
    }
  }
  return html.join('')
}

export default {
  name: 'Intervention',
  props: {
    intervention: Object,
    key: Number,
    participants: Object,
    point: String,
    curMotion: String,
    meetingCode: String
  },
  computed: {
    summary () {
      return this.markdownify('<span class="intervener">' + this.participants[this.intervention.participant] + '</span>' + this.$t('speech.participantseparator') + this.intervention.summary)
    }
  },
  methods: {
    markdownify (text) {
      return xss.process(VMdEditor.vMdParser.themeConfig.markdownParser.render(text))
    },
    diff (text1, text2) {
      const diff = dmp.diff_main(text1, text2)
      dmp.diff_cleanupSemantic(diff)
      return dmp.toHtml(diff)
    },
    updateMotion (motion) {
      return this.diff(motion.old_text, motion.operative)
    }
  }
}
</script>
