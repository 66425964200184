import store from '../store'

const API_URL = process.env.VUE_APP_API_ROOT + 'accounts/'

class AuthService {
  login (user) {
    return fetch(API_URL + 'token/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: user.username,
        password: user.password
      })
    })
      .then(response => {
        if (!response.ok) throw new Error()
        return response.json()
      })
      .then(data => {
        data.username = user.username
        if (data.access) {
          localStorage.setItem('user', JSON.stringify(data))
        }
        return data
      })
      .catch((error) => {
        console.log(error)
      })
  }

  refresh (user) {
    const prom = fetch(API_URL + 'token/refresh/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        refresh: user.refresh
      })
    })
      .then(response => {
        if (!response.ok) throw new Error()
        return response.json()
      })
    prom.then(data => {
      user.access = data.access
      if (data.access) {
        localStorage.setItem('user', JSON.stringify(user))
      }
      return user
    })
      .catch(() => {
        store.dispatch('auth/logout', this).then(() => {})
      })
    return prom
  }

  logout () {
    localStorage.removeItem('user')
  }

  register (user) {
    return fetch(API_URL + 'new/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: user.username,
        email: user.email,
        password: user.password
      })
    })
  }

  verify (user) {
    return fetch(API_URL + 'token/verify/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: user.access
      })
    })
      .then(response => {
        if (!response.ok) throw new Error()
        return response.json()
      })
  }
}

export default new AuthService()
