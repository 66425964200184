<template>
  <NoSidebar>
    <FormContainer>
      <h1>{{ $t('meeting.join') }}</h1>

      <form @submit.prevent="handleCreate">
        <div class="form-group">
          <label for="id_name">{{ $t('formfield.name') }}</label>
          <input type="text" v-model="name" name="name" id="id_name" class="form-control"/>
        </div>
        <button type="submit" name="continue" class="focus">{{ $t('meeting.joinbutton') }}</button>
      </form>
    </FormContainer>
  </NoSidebar>
</template>

<script>
import NoSidebar from '@/components/NoSidebar.vue'
import FormContainer from '@/components/FormContainer.vue'
import authedRequest from '@/services/authedRequest.js'

export default {
  name: 'JoinMeetingForm',
  components: {
    NoSidebar,
    FormContainer
  },
  props: {
    orgSlug: String,
    meetingSlug: String
  },
  data () {
    return {
      name: '',
      slug: ''
    }
  },
  methods: {
    handleCreate () {
      authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, 'participants/'], 'POST', {
        name: this.name
      }).then(data => {
        this.$router.push({
          name: 'Member Dashboard',
          params: { orgSlug: this.orgSlug, meetingSlug: this.meetingSlug, participant: data.id }
        })
      })
    }
  }
}
</script>
