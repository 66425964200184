<template>
  <component :is="'h'+Math.min(depth).toString()" :id="'p-'+parent+point.seq.toString()">{{ pointNumber }} {{ point.name }}</component>
  <Intervention v-for="intervention in point.interventions" :key="intervention.id" :intervention="intervention" :participants="participants" :point="pointNumber" curMotion="" :meetingCode="meetingCode" />
  <MinutesPoint v-for="cPoint in point.subpoints" :key="cPoint.id" :depth="Number(depth)+1" :parent="pointNumber+'.'" :point="cPoint" :participants="participants" :meetingCode="meetingCode"/>
</template>
<script>
import Intervention from './Intervention.vue'

export default {
  name: 'MinutesPoint',
  components: {
    Intervention
  },
  props: {
    depth: Number,
    point: Object,
    key: Number,
    parent: String,
    participants: Object,
    meetingCode: String
  },
  computed: {
    pointNumber () {
      return this.parent + this.point.seq.toString()
    }
  }
}
</script>
