<template>

    <h1>{{ $t('Change Password') }}</h1>

    <form>
      <div class="form-group">
        <label for="id_cur_password">{{ $t('Current Password') }}</label>
        <input type="password" name="cur_password" id="id_cur_password" class="form-control"/>
      </div>
      <div class="form-group">
        <label for="id_password">{{ $t('New Password') }}</label>
        <input type="password" name="password" id="id_password" class="form-control"/>
      </div>
      <div class="form-group">
        <label for="id_confirm_password">{{ $t('Confirm Password') }}</label>
        <input type="password" name="confirm_password" id="id_confirm_password" class="form-control"/>
      </div>
      <button type="submit" name="continue" class="focus">{{ $t('Change Password') }}</button>
    </form>
</template>

<script>
export default {
  name: 'ChangePasswordForm',
  props: {}
}
</script>
