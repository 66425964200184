<template>

    <h1>{{ $t('Reset Password') }}</h1>

    <form>
      <div class="form-group">
        <label for="id_email">{{ $t('Email Address') }}</label>
        <input type="email" name="cur_email" id="id_email" class="form-control"/>
      </div>
      <button type="submit" name="continue" class="focus">{{ $t('Reset Password') }}</button>
    </form>
</template>

<script>
export default {
  name: 'ResetPasswordForm',
  props: {}
}
</script>
