<template>

    <h1>{{ $t('accounts.registration') }}</h1>

    <form @submit.prevent="handleRegister">
      <div class="form-group">
        <label for="id_username">{{ $t('accounts.username') }}</label>
        <input type="text" v-model="username" name="username" id="id_username" class="form-control"/>
      </div>
      <div class="form-group">
        <label for="id_email">{{ $t('accounts.email') }}</label>
        <input type="email" v-model="email" name="email" id="id_email" class="form-control"/>
      </div>
      <div class="form-group">
        <label for="id_password">{{ $t('accounts.password') }}</label>
        <input type="password" v-model="password" name="password" id="id_password" class="form-control"/>
      </div>
      <div class="form-group">
        <label for="id_confirm_password">{{ $t('accounts.confirmpass') }}</label>
        <input type="password" name="confirm_password" id="id_confirm_password" class="form-control"/>
      </div>
      <button type="submit" name="continue" class="focus">{{ $t('accounts.register') }}</button>
    </form>
</template>

<script>
export default {
  name: 'RegistrationForm',
  props: {},
  data () {
    return {
      successful: false,
      loading: false,
      message: '',
      username: '',
      email: '',
      password: ''
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    }
  },
  mounted () {
    if (this.loggedIn) {
      this.$router.push({ name: 'Home' })
    }
  },
  methods: {
    handleRegister () {
      this.message = ''
      this.successful = false
      this.loading = true

      this.$store.dispatch('auth/register', this).then(
        (data) => {
          this.successful = true
          this.loading = false
          this.$store.dispatch('auth/login', { username: this.username, password: this.password }).then(
            () => {
              if (this.$route.query.from) {
                this.$router.replace(this.$route.query.from)
              } else {
                this.$router.replace({ name: 'Home' })
              }
            })
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          this.successful = false
          this.loading = false
        }
      )
    }
  }
}
</script>
