<template>
  <WithSidebar>
    <template v-slot:sidebar>
      <MyOrganisationsList/>
    </template>
    <template v-slot:default>
      <img v-if="organisation.logo !== null" :src="organisation.logo" :alt="$t('main.logo', { org: organisation.name })"/>
      <p>{{ organisation.name }}</p>
      <h2>{{ $tc('orgs.meetings', meetings.length) }}</h2>
      <ul v-if="meetings.length > 0">
        <li v-for="meeting in meetings" :key="meeting">
          {{ meeting.name }}
          (<router-link :to="{ name: 'Agenda', params: { orgSlug: orgSlug, meetingSlug: meeting.slug } }">{{ $t('agenda.title') }}</router-link> |
          <router-link :to="{ name: 'Minutes', params: { orgSlug: orgSlug, meetingSlug: meeting.slug } }">{{ $t('minutes.title') }}</router-link> |
          <router-link :to="{ name: 'Join Meeting', params: { orgSlug: orgSlug, meetingSlug: meeting.slug } }">{{ $t('meeting.joinbutton') }}</router-link>)
        </li>
      </ul>
      <ul>
        <li><router-link :to="{ name: 'New Meeting', params: { orgSlug: orgSlug } }">{{ $t('orgs.newmeeting') }}</router-link></li>
      </ul>
    </template>
  </WithSidebar>
</template>
<script>
import WithSidebar from '@/components/WithSidebar.vue'
import MyOrganisationsList from '@/components/aside/MyOrganisationsList.vue'
import authedRequest from '@/services/authedRequest.js'

export default {
  name: 'OrganisationDetail',
  components: {
    MyOrganisationsList,
    WithSidebar
  },
  data () {
    return {
      organisation: Object,
      meetings: Array
    }
  },
  props: {
    orgSlug: String
  },
  created () {
    authedRequest(['organisations', this.orgSlug, '']).then(data => {
      this.organisation = data
    })
    authedRequest(['organisations', this.orgSlug, 'meetings/']).then(data => {
      this.meetings = data
    })
  }
}
</script>
<style scoped lang="scss">
</style>
