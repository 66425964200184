<template>
  <NoSidebar>
    <h1 class="current-speaker">{{ $t('meeting.currentspeaker', { name: participants[currentSpeaker] }) }}<span class="time-remaining">{{ timer.currentTimer }}</span></h1>
    <h2>{{ $t('meeting.speakerorder') }}</h2>
    <ol id="speaker-order">
      <li v-for="req in requests" :key="req">{{ participants[req.participant] }}</li>
    </ol>
  </NoSidebar>
</template>
<script>
import NoSidebar from '@/components/NoSidebar.vue'
import AuthService from '@/services/auth.service.js'
import authedRequest from '@/services/authedRequest.js'

export default {
  name: 'SpeakerListView',
  components: {
    NoSidebar
  },
  props: {
    orgSlug: String,
    meetingSlug: String
  },
  data () {
    return {
      participants: {},
      requests: [],
      currentSpeaker: 0,
      currentPoint: 0,
      currentMotion: 0,
      timer: {
        currentTimer: '',
        interval: null
      }
    }
  },
  created () {
    authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, 'participants/']).then(data => {
      this.participants = data.reduce((a, v) => ({ ...a, [v.id]: v.name }), {})
    })
    authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, '']).then(data => {
      this.currentPoint = data.sessions[0].points[0].id
      this.currentMotion = null

      authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, 'points', this.currentPoint, '']).then(d => {
        this.pPoint = d.interventions.filter(x => x.seq !== null)
        d.interventions.filter(x => x.seq === null).forEach(x => {
          this.handleAdd(x)
        })
      })
    })

    AuthService.refresh(this.$store.state.auth.user).then(data => {
      this.speakerWS = new WebSocket(
        [process.env.VUE_APP_WS_ROOT, this.orgSlug, this.meetingSlug, 'order', '?token=' + this.$store.state.auth.user.access].join('/')
      )
      this.speakerWS.onmessage = (event) => {
        const data = JSON.parse(event.data)
        switch (data.action) {
          case 'add_to_list':
            this.handleAdd(data.intervention)
            break
          case 'remove_from_list':
            this.handleRemove(event.intervention)
            break
          case 'clear_list':
            this.clearList(data)
            break
          case 'give_floor':
            this.handleFloor(data.intervention)
            break
          case 'start_timer':
            this.handleStartTimer(data.length)
            break
          case 'stop_timer':
            this.handleStopTimer(data.length)
            break
          default:
            break
        }
      }
    })
  },
  methods: {
    handleAdd (intervention) {
      if (intervention.point === this.currentPoint && intervention.motion === this.currentMotion) {
        // const index = this.requests.findIndex(int => int.participant === intervention.participant)
        // if (index === -1) {
        this.requests.push(intervention)
        // }
      }
    },
    handleRemove (intervention) {
      if (intervention.point === this.currentPoint && intervention.motion === this.currentMotion) {
        const index = this.requests.findIndex(int => int.participant === intervention.participant)
        if (index > -1) {
          this.requests.splice(index, 1)
        }
      }
    },
    clearList (data) {
      if (data.point === this.currentPoint && data.motion === this.currentMotion) {
        this.requests = []
      }
    },
    handleFloor (intervention) {
      if (intervention.point === this.currentPoint && intervention.motion === this.currentMotion) {
        this.currentSpeaker = intervention.participant

        const index = this.requests.findIndex(int => int.id === intervention.id)
        if (index > -1) {
          this.requests.splice(index, 1)
        }
      }
    },
    handleStartTimer (duration) {
      var timer = duration; var minutes; var seconds
      this.timer.interval = setInterval(() => {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10)

        minutes = Math.abs(minutes)
        seconds = Math.abs(seconds)
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds

        this.timer.currentTimer = ((timer-- < 0) ? '-' : '') + minutes + ':' + seconds
      }, 1000)
    },
    handleStopTimer () {
      clearInterval(this.timer.interval)
      this.timer.currentTimer = ''
    }
  }
}
</script>
<style scoped type="scss">
aside {
  display: none;
}
main {
  margin-left: unset;
}
.current-speaker {
  text-align: left;

  .time-remaining {
    text-align: right;
  }
}
</style>
