<template>
  <NoSidebar>
    <div class="form-container">
      <h1>{{ p.name }}</h1>
      <div v-if="isSpeaking">{{ $t('meeting.yourfloor') }}<span class="time-remaining">{{ timer.currentTimer }}</span></div>
      <button v-if="floorRequested" @click.prevent="revokeFloor">{{ $t('meeting.revokefloor') }}</button>
      <button v-else @click.prevent="requestFloor">{{ $t('meeting.requestfloor') }}</button>
    </div>
  </NoSidebar>
</template>
<script>
import NoSidebar from '@/components/NoSidebar.vue'
import AuthService from '@/services/auth.service.js'
import authedRequest from '@/services/authedRequest.js'

export default {
  name: 'MemberDashboard',
  components: {
    NoSidebar
  },
  props: {
    orgSlug: String,
    meetingSlug: String,
    participant: String
  },
  data () {
    return {
      currentPoint: 0,
      currentMotion: null,
      p: {},
      floorRequested: false,
      isSpeaking: false,
      timer: {
        currentTimer: null,
        interval: null
      }
    }
  },
  created () {
    authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, 'participants', this.participant, '']).then(data => {
      this.p = data
    })
    authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, '']).then(data => {
      this.currentPoint = data.sessions[0].points[0].id
      this.currentMotion = null
    })

    AuthService.refresh(this.$store.state.auth.user).then(data => {
      this.speakerWS = new WebSocket(
        [process.env.VUE_APP_WS_ROOT, this.orgSlug, this.meetingSlug, 'order', '?token=' + this.$store.state.auth.user.access].join('/')
      )
      this.speakerWS.onmessage = (event) => {
        const data = JSON.parse(event.data)
        switch (data.action) {
          case 'clear_list':
            this.floorRequested = false
            break
          case 'give_floor':
            if (data.intervention.participant === this.p.id) {
              this.floorRequested = false
              this.isSpeaking = true
            } else {
              this.isSpeaking = false
            }
            break
          case 'start_timer':
            this.handleStartTimer(data.length)
            break
          case 'stop_timer':
            this.handleStopTimer(data.length)
            break
          default:
            break
        }
      }
    })
  },
  methods: {
    requestFloor (event) {
      this.floorRequested = true
      this.speakerWS.send(JSON.stringify({
        type: 'add_participant',
        participant: this.p.id,
        point: this.currentPoint,
        motion: this.currentMotion
      }))
    },
    revokeFloor (event) {
      this.floorRequested = false
      this.speakerWS.send(JSON.stringify({
        type: 'remove_participant',
        participant: this.p.id,
        point: this.currentPoint,
        motion: this.currentMotion
      }))
    },
    handleStartTimer (duration) {
      var timer = duration; var minutes; var seconds
      this.timer.interval = setInterval(() => {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10)

        minutes = Math.abs(minutes)
        seconds = Math.abs(seconds)
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds

        this.timer.currentTimer = ((timer-- < 0) ? '-' : '') + minutes + ':' + seconds
      }, 1000)
    },
    handleStopTimer () {
      clearInterval(this.timer.interval)
      this.timer.currentTimer = ''
    }
  }
}
</script>
