<template>
  <WithSidebar>
    <template v-slot:default>
      <div></div>
    </template>
  </WithSidebar>
</template>
<script>
import WithSidebar from '@/components/WithSidebar.vue'

export default {
  name: 'SecretaryDashboard',
  components: {
    WithSidebar
  },
  props: {
    orgSlug: String,
    meetingSlug: String,
    participant: String
  },
  mounted: {}
}
</script>
