<template>
  <main><slot/></main>
</template>
<script>
export default {
  name: 'NoSidebar'
}
</script>
<style scoped lang="scss">
main {
  padding: 1em;
}
</style>
