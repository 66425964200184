<template>
  <draggable
    class="dragArea"
    tag="ol"
    :list="list"
    :group="{ name: 'g1' }"
    item-key="name"
  >
    <template #item="{ element }">
      <li>{{ element.name }} <a @click="remove(index)">X</a>
        <nested-draggable :points="element.subpoints" />
      </li>
    </template>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: {
    points: {
      required: true,
      type: Array
    }
  },
  components: {
    draggable
  },
  name: 'nested-draggable',
  computed: {
    list () {
      return this.points
    }
  },
  methods: {
    remove (index) {
      this.list.splice(index, 1)
    }
  }
}
</script>
<style scoped>
.dragArea {
  min-height: 50px;
  outline: 1px dashed;
}
</style>
