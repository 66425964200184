<template>
  {{ point.name }}
  <i v-if="point.start_at">({{ $t('agenda.startat', { time: $d(point.start_at, 'time') }) }})</i>
  <i v-if="point.continued">({{ $t('agenda.pointcontinued') }})</i>
  <ol v-if="point.subpoints.length > 0">
    <li v-for="p in point.subpoints" :key="p" :value="p.seq.toString()"><PointItem :point="p" :parent="parent+point.seq+'.'"/></li>
  </ol>
</template>
<script>
export default {
  name: 'PointItem',
  props: {
    point: Object,
    parent: String
  }
}
</script>
