import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'

import VMdEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import Prism from 'prismjs'
import 'prismjs/components/prism-json'
import enUS from '@kangc/v-md-editor/lib/lang/en-US'

VMdEditor.use(vuepressTheme, { Prism })
VMdEditor.lang.use('en-US', enUS)

const i18n = createI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
  datetimeFormats: {
    en: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      fulldt: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      },
      time: {
        hour: 'numeric',
        minute: 'numeric'
      }
    },
    fr: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      fulldt: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      },
      time: {
        hour: 'numeric',
        minute: 'numeric'
      }
    }
  },
  messages: {
    en: {
      main: {
        logo: '{org} logo',
        welcome: 'Welcome to Lavenue',
        neworg: 'Add New Organisation',
        purpose: 'Lavenue will be a new web-app to assist in the organisation and record-keeping of meetings following parliamentary procedure.'
      },
      accounts: {
        login: 'Login',
        loginbutton: 'Log in',
        username: 'Username',
        password: 'Password',
        logout: 'Log out ({user})',
        email: 'Email address',
        confirmpass: 'Confirm password',
        registration: 'Registration',
        register: 'Register',
        createnew: 'Create an account'
      },
      speech: {
        participantseparator: ': '
      },
      motion: {
        sponsorlabel: 'Sponsor: | Sponsors: ',
        voterequest: 'Vote requested by:',
        acclaimed: 'Motion adopted by acclamation.',
        dropped: 'Motion dropped.',
        passes: 'Motion passes by {favour}-{oppose}-{abstain}.',
        fails: 'Motion fails by {favour}-{oppose}-{abstain}.'
      },
      meeting: {
        join: 'Join Meeting',
        joinbutton: 'Join Meeting',
        currentspeaker: 'Current Speaker: {name}',
        speakerorder: 'Speaking Requests',
        requestfloor: 'Request the floor',
        settimer: 'Set timer',
        starttimer: 'Start',
        stoptimer: 'Stop',
        revokefloor: 'Cancel floor request',
        yourfloor: 'You have the floor'
      },
      minutes: {
        title: 'Minutes',
        participants: 'Participant | Participants',
        members: 'Present Member | Present Members',
        observers: 'Present Observer | Present Observers',
        presidents: 'President | Presidents',
        secretaries: 'Secretary | Secretaries',
        proceedings: 'Proceedings',
        deposeddocs: 'Submitted Document | Submitted Documents',
        adoptedmotions: 'Codified Resolution | Codified Resolutions'
      },
      agenda: {
        title: 'Agenda',
        session: '{session} Session',
        pointcontinued: 'continued',
        startat: 'Must start at {time}'
      },
      orgs: {
        newmeeting: 'New Meeting',
        meetings: 'Meeting | Meetings',
        meetingsession: 'Session {i}',
        sessiontime: 'Time',
        addpoint: 'Add a point',
        addpointbutton: 'Add',
        addsession: 'Add a session',
        newmeetingsubmit: 'Create Meeting',
        neworg: 'New Organisation',
        neworgsubmit: 'Create Organisation'
      },
      formfield: {
        name: 'Name',
        slug: 'Abbreviation (no spaces)'
      }
    },
    fr: {
      main: {
        logo: '{org} logo',
        welcome: 'Bienvenue à Lavenue',
        neworg: 'Créer Nouvelle Organisation',
        purpose: 'Lavenue sera un application web pour l\'administration de réunions suivant un guide parlementaire.'
      },
      accounts: {
        login: 'Connexion',
        loginbutton: 'Connecter',
        username: 'Nom d\'utilisateur',
        password: 'Mot de passe',
        logout: 'Déconnexion ({user})',
        email: 'Adresse courriel',
        confirmpass: 'Confirmer mot de passe',
        registration: 'Inscription',
        register: 'S\'inscrire',
        createnew: 'Créer une nouvelle compte'
      },
      speech: {
        participantseparator: ' : '
      },
      motion: {
        sponsorlabel: 'Appui : | Appuis : ',
        voterequest: 'Vote demandé par : ',
        acclaimed: 'La proposition est adoptée à l\'unanimité.',
        dropped: 'La proposition est tombée.',
        passes: 'La proposition est adoptée à {favour}-{oppose}-{abstain}.',
        fails: 'La proposition est battue à {favour}-{oppose}-{abstain}.'
      },
      meeting: {
        join: 'Rejoindre réunion',
        joinbutton: 'Rejoindre',
        currentspeaker: 'Orateur·trice actuel·le : {name}',
        speakerorder: 'Demandes de parole',
        requestfloor: 'Demander la parole',
        settimer: 'Chronomètre',
        starttimer: 'Commencer',
        stoptimer: 'Arrêter',
        revokefloor: 'Annuler demande de parole',
        yourfloor: 'Vous avez la parole'
      },
      minutes: {
        title: 'Procès-verbal',
        participants: 'Participant | Participants',
        members: 'Membre Présent·e | Membres Présent·e·s',
        observers: 'Observateur·trice Présent·e | Observateur·trice·s Présent·e·s',
        presidents: 'Présidence | Présidence',
        secretaries: 'Secrétariat | Secrétariat',
        proceedings: 'Compte-rendu',
        deposeddocs: 'Document déposé | Documents déposés',
        adoptedmotions: 'Résolution codifiée | Résolutions codifiées'
      },
      agenda: {
        title: 'Ordre du jour',
        session: 'Séance du {session}',
        pointcontinued: 'suite',
        startat: 'Doit commencer à {time}'
      },
      orgs: {
        newmeeting: 'Nouvelle Réunion',
        meetings: 'Réunion | Réunions',
        meetingsession: 'Séance {i}',
        sessiontime: 'Temps',
        addpoint: 'Ajouter point',
        addpointbutton: 'Ajouter',
        addsession: 'Ajouter une séance',
        newmeetingsubmit: 'Créer Réunion',
        neworg: 'Nouvelle Organisation',
        neworgsubmit: 'Create Organisation'
      },
      formfield: {
        name: 'Nom',
        slug: 'Abréviation (sans espaces)'
      }
    }
  }
})

createApp(App).use(VMdEditor).use(i18n).use(store).use(router).mount('#app')
