import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import LoginForm from '@/views/accounts/LoginForm.vue'
import RegistrationForm from '@/views/accounts/RegistrationForm.vue'
import ChangePasswordForm from '@/views/accounts/ChangePasswordForm.vue'
import ResetPasswordForm from '@/views/accounts/ResetPasswordForm.vue'
import ConfirmResetPasswordForm from '@/views/accounts/ConfirmResetPasswordForm.vue'
import MinutesView from '@/views/minutes/main/MinutesView.vue'
import AgendaView from '@/views/agenda/AgendaView.vue'
import NewMeetingForm from '@/views/NewMeetingForm.vue'
import NewOrganisationForm from '@/views/NewOrganisationForm.vue'
import OrganisationDetail from '@/views/OrganisationDetail.vue'

import JoinMeetingForm from '@/views/JoinMeetingForm.vue'
import SpeakerListView from '@/views/SpeakerListView.vue'
import CurrentMotionView from '@/views/CurrentMotionView.vue'
import VoteView from '@/views/VoteView.vue'
import MemberDashboard from '@/views/dashboards/MemberDashboard.vue'
import PresidentDashboard from '@/views/dashboards/PresidentDashboard.vue'
import SecretaryDashboard from '@/views/dashboards/SecretaryDashboard.vue'

import AccountsFormContainer from '@/components/AccountsFormContainer.vue'

import AuthService from '../services/auth.service.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/accounts',
    component: AccountsFormContainer,
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginForm
      },
      {
        path: 'register',
        name: 'Register',
        component: RegistrationForm
      },
      {
        path: 'change-password',
        name: 'Change Password',
        component: ChangePasswordForm,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reset',
        name: 'Reset Password',
        component: ResetPasswordForm
      },
      {
        path: 'reset/:uidb64/:token',
        name: 'Confirm Password Reset',
        component: ConfirmResetPasswordForm
      }
    ]
  },
  {
    path: '/new-org',
    name: 'New Organisation',
    component: NewOrganisationForm
  },
  {
    path: '/:orgSlug',
    props: true,
    name: 'Organisation',
    component: OrganisationDetail
  },
  {
    path: '/:orgSlug/:meetingSlug/minutes',
    props: true,
    name: 'Minutes',
    component: MinutesView
  },
  {
    path: '/:orgSlug/:meetingSlug/agenda',
    props: true,
    name: 'Agenda',
    component: AgendaView
  },
  {
    path: '/:orgSlug/:meetingSlug/join',
    props: true,
    name: 'Join Meeting',
    component: JoinMeetingForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:orgSlug/:meetingSlug/speaker-list',
    props: true,
    name: 'Speaker List',
    component: SpeakerListView
  },
  {
    path: '/:orgSlug/:meetingSlug/current-motion',
    props: true,
    name: 'Current Motion',
    component: CurrentMotionView
  },
  {
    path: '/:orgSlug/:meetingSlug/current-motion/vote',
    props: true,
    name: 'Vote Progress',
    component: VoteView
  },
  {
    path: '/:orgSlug/:meetingSlug/:participant/president',
    props: true,
    name: 'President Dashboard',
    component: PresidentDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:orgSlug/:meetingSlug/:participant/secretary',
    props: true,
    name: 'Secretary Dashboard',
    component: SecretaryDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:orgSlug/:meetingSlug/:participant/member',
    props: true,
    name: 'Member Dashboard',
    component: MemberDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:orgSlug/new',
    props: true,
    name: 'New Meeting',
    component: NewMeetingForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
  /* {
    path: '/organisations',
    component: PassThrough,
    children: [
      {
        path: '',
        name: 'Organisations List',
        component: OrganisationsList
      },
      {
        path: '/new',
        name: 'New Organisation',
        component: NewOrganisationForm
      }
    ]
  },
  {
    path: '/:org',
    component: PassThrough,
    children: [
      {
        path: '',
        name: 'Organisation Detail',
        component: OrganisationDetail
      },
      {
        path: '/new',
        name: 'New Meeting',
        component: NewMeetingForm
      },
      {
        path: '/:org/:meeting',
        name: 'Meeting Detail',
        component: MeetingDetail
      },
      {
        path: '/:org/:meeting/register',
        name: 'Meeting Registration',
        component: MeetingRegistration
      },
      {
        path: '/:org/:meeting/dais',
        name: 'Meeting Dais',
        component: DaisView,
        children: [
          {
            path: '/participants',
            name: 'Dais Participants List',
            component: DaisParticipantsView
          }
        ]
      },
      {
        path: '/:org/:meeting/secretariat',
        name: 'Meeting Secretariat',
        component: SecretariatView,
        children: [
          {
            path: '/participants',
            name: 'Secretariat Participants List',
            component: SecretariatParticipantsView
          },
          {
            path: '/motions',
            component: PassThrough,
            children: [
              {
                path: '/new',
                name: 'Secretariat New Motion',
                component: SecretariatMotionForm
              },
              {
                path: '/:id',
                name: 'Secretariat Motion Detail',
                component: SecretariatMotionForm
              }
            ]
          },
          {
            path: '/speech/:id',
            name: 'Secretariat Speech',
            component: SecretariatSpeechForm
          }
        ]
      },
      {
        path: '/:org/:meeting/participant',
        name: 'Meeting Participant',
        component: MeetingParticipantView
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }, */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Check if logged in
  if (to.meta.requiresAuth) {
    const loginPage = { name: 'login', query: { from: window.location.pathname } }
    const user = JSON.parse(localStorage.getItem('user'))
    if (!user) {
      next(loginPage)
    }
    AuthService.verify(user).then(data => {
      next()
    }).catch(() => {
      AuthService.refresh(user).then(data => {
        next()
      }).catch(() => {
        next(loginPage)
      })
    })
  } else {
    next()
  }
})

export default router
