<template>
  <div class="form-container">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'FormContainer',
  props: {}
}
</script>
