import AuthService from './auth.service.js'

export default function authedRequest (path, method = 'GET', payload = '', retry = true) {
  const user = JSON.parse(localStorage.getItem('user'))
  const url = process.env.VUE_APP_API_ROOT + path.join('/')
  const args = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    method: method
  }

  if (user && user.access) {
    args.headers.Authorization = 'Bearer ' + user.access
  }

  if (method !== 'GET') {
    args.body = JSON.stringify(payload)
  }

  return fetch(url, args)
    .then(response => {
      if (user && retry && response.status === 401) {
        return AuthService.refresh(user).then(data => {
          return authedRequest(path, method, payload, false)
        })
      }
      return response.json()
    })
}
