<template>
  <ul>
    <li v-for="org in organisations" :key="org">
      <router-link :to="{ name: 'Organisation', params: { orgSlug: org.slug } }">
        <img v-if="org.logo !== null" :src="org.logo" :alt="$t('main.logo', { org: org.name })"/>
        <div>{{ org.name }}</div>
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'New Organisation' }"><strong>{{ $t('main.neworg') }}</strong></router-link>
    </li>
  </ul>
</template>
<script>
import authedRequest from '@/services/authedRequest.js'

export default {
  name: 'MyOrganisationsList',
  data () {
    return {
      organisations: Array
    }
  },
  created () {
    authedRequest(['organisations/']).then(data => {
      this.organisations = data
    })
  }
}
</script>
<style scoped lang="scss">
ul {
  list-style: none;
  padding: 0;

  li {
    display: block;
    border: 1px solid #eaeaea;
    border-radius: 7px;
    padding: 0.5em;
    margin-top: 1em;

    a {
      display: flex;

      img {
        max-width: 45%;
        max-height: 80px;
        margin-right: 1em;
      }

      div {
        align-self: center;
      }
    }
  }
}
</style>
