<template>
  <NoSidebar>
    <FormContainer>
      <router-view/>
    </FormContainer>
  </NoSidebar>
</template>

<script>
import NoSidebar from '@/components/NoSidebar.vue'
import FormContainer from '@/components/FormContainer.vue'

export default {
  name: 'LoginForm',
  components: {
    FormContainer,
    NoSidebar
  }
}
</script>
