<template>
  <h1>{{ $t('accounts.login') }}</h1>

  <form @submit.prevent="handleLogin">
    <div class="form-group">
      <label for="id_username">{{ $t('accounts.username') }}</label>
      <input type="text" v-model="username" name="username" id="id_username" class="form-control"/>
    </div>
    <div class="form-group">
      <label for="id_password">{{ $t('accounts.password') }}</label>
      <input type="password" v-model="password" name="password" id="id_password" class="form-control"/>
    </div>
    <button type="submit" name="continue" class="focus">{{ $t('accounts.loginbutton') }}</button>
  </form>
  <p><router-link :to="{ name: 'Register', query: { from: $route.query.from || '/' } }">{{ $t('accounts.createnew') }}</router-link></p>
</template>

<script>
export default {
  name: 'LoginForm',
  props: {},
  data () {
    return {
      loading: false,
      message: '',
      username: '',
      password: ''
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    }
  },
  methods: {
    handleLogin () {
      this.loading = true

      this.$store.dispatch('auth/login', this).then(
        () => {
          if (this.$route.query.from) {
            this.$router.replace(this.$route.query.from)
          } else {
            this.$router.replace({ name: 'Home' })
          }
        },
        (error) => {
          this.loading = false
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    }
  }
}
</script>
