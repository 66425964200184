<template>
  <WithSidebar>
    <template v-slot:sidebar><MyOrganisationsList/></template>
    <template v-slot:default>
      <h1>{{ $t('main.welcome') }}</h1>
      <p>{{ $t('main.purpose') }}</p>
    </template>
  </WithSidebar>
</template>

<script>
import WithSidebar from '@/components/WithSidebar.vue'
import MyOrganisationsList from '@/components/aside/MyOrganisationsList.vue'

export default {
  name: 'Home',
  components: {
    MyOrganisationsList,
    WithSidebar
  }
}
</script>
