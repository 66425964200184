<template>
  <template v-for="session in agenda.sessions" :key="session">
    <component :is="tag" class="session-heading">{{ $t('agenda.session', { session: $d(new Date(session.start), 'fulldt') }) }}</component>
    <ol>
      <li v-for="point in session.points" :key="point" :value="point.seq.toString()"><PointItem :point="point" parent=""/></li>
    </ol>
  </template>
</template>
<script>
import PointItem from './PointItem.vue'

export default {
  name: 'MinutesAgenda',
  components: {
    PointItem
  },
  props: {
    agenda: Object,
    tag: {
      type: String,
      default: 'div'
    }
  }
}
</script>

<style type="scss">
.session-heading {
  font-weight: bold;
}
</style>
