<template>
  <NoSidebar>
    <div></div>
  </NoSidebar>
</template>
<script>
import NoSidebar from '@/components/NoSidebar.vue'

export default {
  name: '',
  components: {
    NoSidebar
  },
  props: {
    orgSlug: String,
    meetingSlug: String
  },
  mounted: {}
}
</script>
