<template>
  <NoSidebar>
    <div></div>
  </NoSidebar>
</template>
<script>
import NoSidebar from '@/components/NoSidebar.vue'

export default {
  name: 'CurrentMotionView',
  components: {
    NoSidebar
  },
  props: {
    point: Object,
    meetingSlug: String
  }
}
</script>
