<template>
  <NoSidebar>
    <FormContainer>
      <h1>{{ $t('orgs.newmeeting') }}</h1>

      <form @submit.prevent="handleCreate">
        <div class="form-group">
          <label for="id_name">{{ $t('formfield.name') }}</label>
          <input type="text" v-model="name" name="name" id="id_name" class="form-control"/>
        </div>
        <div class="form-group">
          <label for="id_slug">{{ $t('formfield.slug') }}</label>
          <input type="text" v-model="slug" name="slug" id="id_slug" class="form-control"/>
        </div>
        <template v-for="(session, index) in sessions" :key="session">
          <fieldset>
            <legend>{{ $t('orgs.meetingsession', { i: index+1 }) }}</legend>
            <div class="form-group">
              <label :for="'id_session_' + index">{{ $t('orgs.sessiontime') }}</label>
              <input type="datetime-local" v-model="session.start" :name="'start_' + index" :id="'id_session_' + index" class="form-control"/>
            </div>
            <div class="form-group">
              <nested-draggable :points="session.points"/>
            </div>
            <form @submit.prevent="addPoint(index)">
              <input class="form-control" type="text" :placeholder="$t('orgs.addpoint')" v-model="session.newPoint" />
              <button>{{ $t('orgs.addpointbutton') }}</button>
            </form>
          </fieldset>
        </template>
        <a @click="addSession">{{ $t('orgs.addsession') }}</a>
        <button type="submit" name="continue" class="focus">{{ $t('orgs.newmeetingsubmit') }}</button>
      </form>
    </FormContainer>
  </NoSidebar>
</template>

<script>
import NoSidebar from '@/components/NoSidebar.vue'
import FormContainer from '@/components/FormContainer.vue'
import NestedDraggable from '@/components/NestedDraggable.vue'
import authedRequest from '@/services/authedRequest.js'

export default {
  name: 'NewMeetingForm',
  props: {
    orgSlug: String
  },
  components: {
    NestedDraggable,
    NoSidebar,
    FormContainer
  },
  data () {
    return {
      name: '',
      slug: '',
      sessions: [{ start: null, points: [] }]
    }
  },
  methods: {
    addSession () {
      this.sessions.push({ start: null, points: [] })
    },
    addPoint (index) {
      if (!this.sessions[index].newPoint) {
        return
      }
      this.sessions[index].points.push({ name: this.sessions[index].newPoint, subpoints: [] })
      this.sessions[index].newPoint = ''
    },
    assignSeq (root, start) {
      for (const [i, point] of root.subpoints.entries()) {
        point.seq = i + start
        this.assignSeq(point, 1)
      }
    },
    handleCreate () {
      let seq = 0
      for (const session of this.sessions) {
        delete session.newPoint
        session.start = new Date(session.start).toISOString()
        for (const point of session.points) {
          point.seq = seq++
          this.assignSeq(point, 1)
        }
      }

      authedRequest(['organisations', this.orgSlug, 'meetings/'], 'POST', {
        name: this.name,
        slug: this.slug,
        sessions: this.sessions
      }).then(data => {
        this.$router.push({ name: 'Agenda', params: { orgSlug: this.orgSlug, meetingSlug: data.slug } })
      })
    }
  }
}
</script>
