<template>
  <WithSidebar>
    <FormContainer>
      <h1>{{ $t('orgs.neworg') }}</h1>

      <form @submit.prevent="handleCreate">
        <div class="form-group">
          <label for="id_name">{{ $t('formfield.name') }}</label>
          <input type="text" v-model="name" name="name" id="id_name" class="form-control"/>
        </div>
        <div class="form-group">
          <label for="id_slug">{{ $t('formfield.slug') }}</label>
          <input type="text" v-model="slug" name="slug" id="id_slug" class="form-control"/>
        </div>
        <button type="submit" name="continue" class="focus">{{ $t('orgs.neworgsubmit') }}</button>
      </form>
    </FormContainer>
  </WithSidebar>
</template>

<script>
import WithSidebar from '@/components/WithSidebar.vue'
import FormContainer from '@/components/FormContainer.vue'
import authedRequest from '@/services/authedRequest.js'

export default {
  name: 'NewOrganisationForm',
  components: {
    FormContainer,
    WithSidebar
  },
  props: {
    orgSlug: String
  },
  data () {
    return {
      name: '',
      slug: '',
      sessions: [{ start: null, points: [] }]
    }
  },
  methods: {
    handleCreate () {
      authedRequest(['organisations/'], 'POST', {
        name: this.name,
        slug: this.slug
      }).then(data => {
        this.$router.push({ name: 'Organisation', params: { orgSlug: this.slug } })
      })
    }
  }
}
</script>
