<template>
  <li v-for="motion in adopted" :key="motion" class="motion">
    <a :href="'#m-' + motion.full_num">{{ meetingSlug }}-{{ motion.full_num }}</a>
    <div class="text">
      <div class="preamble" v-html="markdownify(motion.preamble)"></div>
      <div class="operative" v-html="markdownify(motion.adopted_text)"></div>
    </div>
  </li>
  <AdoptedMotionList v-for="p in point.subpoints" :key="p" :point="p" :meetingSlug="meetingSlug"/>
</template>

<script>
import VMdEditor, { xss } from '@kangc/v-md-editor'
export default {
  name: 'AdoptedMotionList',
  components: {},
  props: {
    point: Object,
    meetingSlug: String
  },
  computed: {
    adopted () {
      return this.point.motions.filter(m => m.proposition === 'l.R' && m.votes.some(v => v.passed))
    }
  },
  methods: {
    markdownify (text) {
      return xss.process(VMdEditor.vMdParser.themeConfig.markdownParser.render(text))
    }
  }
}
</script>
