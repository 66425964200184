<template>
  <header>
    <div class="content">
      <router-link :to="{ name: 'Home' }" class="logo">
        <img src="@/assets/logo.svg" :alt="$t('main.logo', { org: 'Lavenue' })" />
        <span class="site-name">Lavenue</span>
      </router-link>
      <div class="current-status"></div>
      <nav>
        <ul v-if="currentUser">
          <li><button @click.prevent="handleLogout">{{ $t('accounts.logout', { user: currentUser.username }) }}</button></li>
        </ul>
        <ul v-else>
          <li><router-link :to="{ name: 'login', query: { from: this.$route.path } }">{{ $t('accounts.login') }}</router-link></li>
        </ul>
      </nav>
    </div>
  </header>
  <router-view/>
  <footer></footer>
</template>

<script>
export default {
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    }
  },
  methods: {
    handleLogout () {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'Home' })
      })
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
body, html {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}

@page {
  @bottom-left {
    content: "Page";
  }
}

header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #eaeaea;
  background: white;

  .content {
    display: flex;
    align-items: center;
    width: 100%;
    padding: .7rem 1.5rem;
    line-height: 2.2rem;
    height: 4.4rem;
  }

  .logo {
    display: inline-block;
    flex-grow: 1;
    text-decoration: none;
    color: unset;

    img {
      height: 3rem;
      display: inline-block;
      padding-right: 0.5rem;
      vertical-align: bottom;
    }
    .site-name {
      font-size: 32px;
      font-family: Avenir, sans-serif;
    }
  }
  nav {
    display: inline-block;

    ul {
      display: inline-block;

      li {
        list-style: none;
      }
    }
  }
}

.form-container {
  margin: 0 auto;
  max-width: 450px;
  border: 1px solid #eaeaea;
  border-radius: 15px;
  padding: 1rem;

  h1 {
    margin-top: 0;
  }

  .form-group {
    margin-bottom: 1rem;

    label {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }

  .form-control {
    display: block;
    border: 1px solid #cecece;
    width: 100%;
    height: 2.25rem;
    border-radius: 7px;
    font-size: 0.9em;
    padding-left: 5px;
  }
}

button {
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 7px;
  padding: 0.5rem;
  background: #cecece;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 0.5rem;
}

button.focus {
  border: 1px solid #00bf8a;
  background: #00bf8a;
  color: white;
}

.intervener {
  font-weight: bold;
}

.discussion {
  border-left: 2px solid black;
  border-bottom: 1px solid black;
  padding-left: 0.5em;
}

.sponsors,.vote-request {
  ul {
    display: inline-block;
    padding-inline-start: 0;
    list-style-type: none;
    margin-block: 0;
  }
  li:after {
    content: ", ";
  }
  li:last-child:after {
    content: "";
  }
}

.motion {
  scroll-margin-top: 5rem;

  .text,.sponsors {
    padding-left: 2em;
    padding-right: 2em;

    .preamble {
      font-style: italic;
    }
    .operative {
      font-weight: bold;
    }
  }
}
h1,h2,h3,h4,h5,h6 {
  scroll-margin-top: 5rem;
}

@media print {
  header {
    display: none;
  }
  aside {
    display: none;
  }
  main {
    scroll-margin-top: 0;
    margin-left: 0;
    text-align: justify;

    .members-list li {
      text-align: left;
    }
  }
  h1,h2,h3,h4,h5,h6 {
    page-break-after: avoid;
  }
  .titlepage {
    page-break-after: always;
  }

  @page {
    @bottom-left {
      content: counter(page) ' of ' counter(pages);
    }
  }
}

@media not print {
  .print-only {
    display: none;
  }
}

.members-list {
  columns: 2;
}

.motion-reference {
  text-align: right;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

</style>
