<template>
  <WithSidebar>
    <template v-slot:sidebar>
      <!--<ul>
        <li><router-link :to="{ name: 'President Member List', props: { orgSlug: orgSlug, meetingSlug: meetingSlug } }">{{ $t('meeting.participantslist') }}</router-link></li>
      </ul>-->
      <ol>
        <li v-for="int in pPoint" :key="int" :value="int.seq">{{ participants[int.participant] }}</li>
      </ol>
    </template>
    <template v-slot:default>
      <p class="current-speaker">{{ $t('meeting.currentspeaker', { name: participants[currentSpeaker] }) }}</p>
      <p class="time-remaining">{{ $t('meeting.settimer') }} <input type="text" v-model="timer.minutes" name="minutes" id="id_minutes" style="width: 29px;" />:<input type="text" v-model="timer.seconds" name="seconds" id="id_seconds" style="width: 29px;"/> <span @click="startTimer">{{ $t('meeting.starttimer') }}</span> <span id="current-timer">{{ timer.currentTimer }}</span> <span @click="stopTimer">{{ $t('meeting.stoptimer') }}</span></p>
      <h2>{{ $t('meeting.speakerorder') }}</h2>
      <ol id="speaker-order">
        <li v-for="req in requests" :key="req" @click="sendGiveFloor(req)">{{ participants[req.participant] }}</li>
      </ol>
    </template>
  </WithSidebar>
</template>
<script>
import WithSidebar from '@/components/WithSidebar.vue'
import AuthService from '@/services/auth.service.js'
import authedRequest from '@/services/authedRequest.js'

export default {
  name: 'SpeakerListView',
  components: {
    WithSidebar
  },
  props: {
    orgSlug: String,
    meetingSlug: String,
    participant: String
  },
  data () {
    return {
      participants: {},
      requests: [],
      currentSpeaker: 0,
      currentPoint: 0,
      currentMotion: 0,
      timer: {
        minutes: 0,
        seconds: 0,
        currentTimer: '',
        interval: null
      },
      pPoint: []
    }
  },
  created () {
    authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, '']).then(data => {
      this.currentPoint = data.sessions[0].points[0].id
      this.currentMotion = null

      authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, 'points', this.currentPoint, '']).then(d => {
        this.pPoint = d.interventions.filter(x => x.seq !== null)
        d.interventions.filter(x => x.seq === null).forEach(x => {
          this.handleAdd(x)
        })
      })
    })
    authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, 'participants/']).then(data => {
      this.participants = data.reduce((a, v) => ({ ...a, [v.id]: v.name }), {})
    })

    AuthService.refresh(this.$store.state.auth.user).then(data => {
      this.speakerWS = new WebSocket(
        [process.env.VUE_APP_WS_ROOT, this.orgSlug, this.meetingSlug, 'order', '?token=' + this.$store.state.auth.user.access].join('/')
      )
      this.speakerWS.onmessage = (event) => {
        const data = JSON.parse(event.data)
        switch (data.action) {
          case 'add_to_list':
            this.handleAdd(data.intervention)
            break
          case 'remove_from_list':
            this.handleRemove(event.intervention)
            break
          case 'clear_list':
            this.clearList(data)
            break
          case 'give_floor':
            this.handleFloor(data.intervention)
            break
          case 'start_timer':
            this.handleStartTimer(data.length)
            break
          case 'stop_timer':
            this.handleStopTimer(data.length)
            break
          default:
            break
        }
      }
    })
  },
  methods: {
    sendGiveFloor (req) {
      this.speakerWS.send(JSON.stringify({
        type: 'give_floor',
        participant: req.participant,
        point: this.currentPoint,
        motion: this.currentMotion
      }))
    },
    startTimer (event) {
      this.speakerWS.send(JSON.stringify({
        type: 'start_timer',
        length: Number(this.timer.minutes) * 60 + Number(this.timer.seconds)
      }))
    },
    stopTimer (event) {
      this.speakerWS.send(JSON.stringify({ type: 'stop_timer' }))
    },
    handleAdd (intervention) {
      if (intervention.point === this.currentPoint && intervention.motion === this.currentMotion) {
        this.requests.push(intervention)
      }
    },
    handleRemove (intervention) {
      if (intervention.point === this.currentPoint && intervention.motion === this.currentMotion) {
        const index = this.requests.findIndex(int => int.participant === intervention.participant)
        if (index > -1) {
          this.requests.splice(index, 1)
        }
      }
    },
    clearList (data) {
      if (data.point === this.currentPoint && data.motion === this.currentMotion) {
        this.requests = []
      }
    },
    handleFloor (intervention) {
      if (intervention.point === this.currentPoint && intervention.motion === this.currentMotion) {
        this.currentSpeaker = intervention.participant

        const index = this.requests.findIndex(int => int.id === intervention.id)
        if (index > -1) {
          this.requests.splice(index, 1)
        }
        this.pPoint.push(intervention)
      }
    },
    handleStartTimer (duration) {
      var timer = duration; var minutes; var seconds
      this.timer.interval = setInterval(() => {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10)

        minutes = Math.abs(minutes)
        seconds = Math.abs(seconds)
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds

        this.timer.currentTimer = ((timer-- < 0) ? '-' : '') + minutes + ':' + seconds
      }, 1000)
    },
    handleStopTimer () {
      clearInterval(this.timer.interval)
      this.timer.currentTimer = ''
    }
  }
}
</script>
