<template>
  <WithSidebar>
    <template v-slot:sidebar>
      <MinutesAgenda :agenda="agenda"/>
    </template>
    <template v-slot:default>
      <div class="titlepage">
        <h1>{{ meeting.name }}</h1>
        <p>{{ orgSlug }}</p>
        <p>{{ $d(meeting.start_time, 'fulldt') }}</p>
      </div>
      <div style="page-break-after: always"></div>
      <h1 id="participants">{{ $tc('minutes.participants', participants.length) }}</h1>
      <template v-for="(mem, name) in {members: members, observers: observers, presidents: presidents, secretaries: secretaries}" :key="(mem, name)">
        <template v-if="mem.length > 0">
          <h2 :id="name + '-list'">{{ $tc('minutes.' + name, mem.length) }}</h2>
          <ul class="members-list">
            <li v-for="member in mem" :key="member">{{ member.name }}</li>
          </ul>
        </template>
      </template>
      <div style="page-break-after: always"></div>
      <h1 id="proceedings">{{ $t('minutes.proceedings') }}</h1>
      <div class="print-only">
        <h2>{{ $t('agenda.title') }}</h2>
        <MinutesAgenda :agenda="agenda" tag="h3"/>
        <div style="page-break-after: always"></div>
      </div>
      <MinutesPoint v-for="point in meeting.points" :key="point.id" :depth="2" parent="" :point="point" :participants="participantNames" :meetingCode="meeting.slug"/>
      <div style="page-break-after: always"></div>
      <h1 id="adopted">{{ $tc('minutes.deposeddocs', 2) }}</h1>
      <div style="page-break-after: always"></div>
      <h1 id="adopted">{{ $tc('minutes.adoptedmotions', adopted.length) }}</h1>
      <ul>
        <AdoptedMotionList v-for="point in adopted" :key="point" :point="point" :meetingSlug="meetingSlug"/>
      </ul>
    </template>
  </WithSidebar>
</template>

<script>
import WithSidebar from '@/components/WithSidebar.vue'
import MinutesAgenda from '../aside/MinutesAgenda.vue'
import MinutesPoint from './MinutesPoint.vue'
import AdoptedMotionList from './AdoptedMotionList.vue'
import authedRequest from '@/services/authedRequest.js'

export default {
  name: 'MinutesView',
  components: {
    MinutesPoint,
    AdoptedMotionList,
    MinutesAgenda,
    WithSidebar
  },
  props: {
    orgSlug: String,
    meetingSlug: String
  },
  data () {
    return {
      meeting: {},
      agenda: {},
      participants: [],
      adopted: [],
      members: [],
      observers: [],
      presidents: [],
      secretaries: [],
      participantNames: {}
    }
  },
  created () {
    authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, 'minutes/']).then(data => {
      this.meeting = data
    })

    authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, 'participants/']).then(data => {
      this.participants = data
      this.participantNames = data.reduce((a, v) => ({ ...a, [v.id]: v.name }), {})

      Object.entries({
        members: x => x.role === 'm' && x.voting > 0,
        observers: x => (x.role === 'm' && x.voting === 0) || x.role === 'o',
        presidents: x => x.role === 'p',
        secretaries: x => x.role === 's'
      }).forEach(([k, v]) => {
        this[k] = data.filter(v)
      })
    })

    authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, 'motions/']).then(data => {
      this.adopted = data.points
    })

    authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, 'agenda/']).then(data => {
      this.agenda = data
    })
  }
}
</script>
<style type="scss">
del {
  background-color: #feeec8;
}
ins {
  background-color: #d8ecff;
}
</style>
