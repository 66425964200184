<template>
  <NoSidebar>
    <template v-for="session in agenda.sessions" :key="session">
      <h2 class="session-heading">{{ $t('agenda.session', { session: $d(new Date(session.start), 'fulldt') }) }}</h2>
      <ol>
        <li v-for="point in session.points" :key="point" :value="point.seq.toString()"><PointItem :point="point" parent=""/></li>
      </ol>
    </template>
  </NoSidebar>
</template>
<script>
import NoSidebar from '@/components/NoSidebar.vue'
import PointItem from './PointItem.vue'
import authedRequest from '@/services/authedRequest.js'

export default {
  name: 'AgendaView',
  components: {
    PointItem,
    NoSidebar
  },
  props: {
    orgSlug: String,
    meetingSlug: String
  },
  data () {
    return {
      agenda: Object
    }
  },
  created () {
    authedRequest(['organisations', this.orgSlug, 'meetings', this.meetingSlug, 'agenda/']).then(data => {
      this.agenda = data
    })
  }
}
</script>

<style type="scss">
.session-heading {
  font-weight: bold;
}
</style>
