<template>
  <aside><slot name="sidebar"/></aside>
  <main><slot/></main>
</template>
<script>
export default {
  name: 'WithSidebar'
}
</script>
<style scoped lang="scss">
aside {
  position: fixed;
  top: 4.4rem;
  left: 0;
  bottom: 0;
  width: 18rem;
  border-right: 1px solid #eaeaea;
  padding: 1em;
  overflow-y: scroll;

  ol {
    padding-left: 2em;
  }
}

main {
  padding: 1em;
  margin-left: 18rem;
}
</style>
